import * as React from 'react'
import { graphql} from 'gatsby'
import { Row } from 'react-bootstrap'
import BlogListItem from '../components/BlogListItem'
import Layout from '../components/Layout'
import Seo from '../components/Seo'

const BlogPage = ({ data }) => {
  return (
    <Layout>
        <Seo title="Posts" description="As software engineers we get stuck. Here are some of the ways we have gotten out."/>
        <h1 className="pt-4 display-5 fw-bold">All Blog Posts</h1>
        <Row className={`py-3`}>
        {
            data.allMdx.edges.map((edge, idx) => (
              <BlogListItem idx={idx} blogData={edge.node}/>
            ))
        }
        </Row>
    </Layout>
  )
}

export const query = graphql`
query blogPage {
  site {
    siteMetadata {
      title
      description
    }
  }
  allMdx(
    filter: {frontmatter: { isPreview: {eq: false }}}
    sort: { order: DESC, fields: [frontmatter___date] }) {
    edges {
      node {
        id
        excerpt(pruneLength: 250)
        slug
        frontmatter {
          date(formatString: "YYYY-MM-DD")
          title
          imagePath
        }
      }
    }
  }
}
`
export default BlogPage