import * as React from "react"
import { Card, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

const BlogListItem = ({ blogData }) => {
    
    var dateArray = blogData.frontmatter.date.split('-');

    return (
        <Col className="py-2" lg={4}>
            <Link to={`/blog/${dateArray[0]}/${dateArray[1]}/${blogData.slug}`} style={{ textDecoration: `none`}}>
                <Card bg="light" text="dark" className="h-100">
                    <Card.Img variant="top" src={blogData.frontmatter.imagePath}/>
                    <Card.Body>
                        <Card.Title style={{minHeight: '20%'}}>{blogData.frontmatter.title}</Card.Title>
                        <Card.Text>{blogData.excerpt}</Card.Text>
                    </Card.Body>
                </Card>
            </Link>
        </Col>
)}

export default BlogListItem